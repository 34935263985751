import React, { useEffect, useState } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { useParams } from 'react-router-dom';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import { styled } from '@material-ui/core/styles';
import { API_ROOT_URL } from './consts';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  padding: '2em',
});

const CalenderInviteContainer = props => {
  const [booking, setBooking] = useState(null);
  const [event, setEvent] = useState(null);
  const { consult_token_id } = useParams();

  useEffect(() => {
    fetch(`${API_ROOT_URL}/booking/${consult_token_id}`)
      .then(response => response.json())
      .then(data => setBooking(data));
  }, []);

  useEffect(() => {
    if (booking !== null) {
      const isDoctor = consult_token_id === booking.practitioner_token;
      const url = `https://video.netcaretech.co.za/${isDoctor ? booking.practitioner_token : booking.patient_token}`;
      const startTime = new Date(booking.datetime);
      const endTime = new Date(startTime.getTime() + 15 * 60000);
      const consultWith = isDoctor ? booking.patient_name : booking.practitioner_name;

      setEvent({
        title: `Consult with ${consultWith}`,
        location: url,
        startTime,
        endTime,
        description: `Consult with ${consultWith}. Join using ${url}`,
      });
    }
  }, [booking]);

  if (event === null)
    return (
      <Container>
        <p style={{ textAlign: 'center' }}>Loading...</p>
      </Container>
    );

  return (
    <Container>
      <AddToCalendar
        buttonTemplate={{ 'calendar-plus-o': 'left' }}
        listItems={[
          { google: 'Google' },
          { apple: 'iCal' },
          { outlook: 'Outlook' },
          { outlookcom: 'Outlook.com' },
          { yahoo: 'Yahoo!' },
        ]}
        event={event}
      />
    </Container>
  );
};

export default CalenderInviteContainer;
