import React from 'react';
import { styled } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import is from 'is_js';

import Controls from './components/Controls/Controls';
import MenuBar, { TOOLBAR_HEIGHT } from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import PostConsult from './components/PostConsult/PostConsult';

import useHeight from './hooks/useHeight/useHeight';
import useWidth from './hooks/useWidth/useWidth';

import useRoomState from './hooks/useRoomState/useRoomState';
import useVideoContext from './hooks/useVideoContext/useVideoContext';

import { requestSocketConnect, requestSocketDisonnect } from './redux/socket/actions';
import { setToken } from './redux/message/actions';
import { useParams } from 'react-router-dom';
import PreConsult from './components/PreConsult/PreConsult';
import BrowserSupportDialog from './components/BrowserSupportDialog/BrowserSupportDialog';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'auto',
});

interface ParamTypes {
  consult_token_id: string;
}

export default function App() {
  const roomState = useRoomState();
  const dispatch = useDispatch();
  const { hasDisconnected } = useVideoContext();
  const { consult_token_id } = useParams<ParamTypes>();
  const height = useHeight();
  const width = useWidth();
  const [showSupportedBrowsers, setShowSupportedBrowsers] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (isUsingSupportedBrowser()) {
      setInitialized(true);
    } else {
      setShowSupportedBrowsers(true);
    }
  }, []);

  React.useEffect(() => {
    dispatch(setToken(consult_token_id));
    connectSocket();
    //
    // const a = setInterval(() => {
    //   dispatch(requestSocketDisonnect());
    // }, 15000);
    //
    // return () => clearInterval(a);
  }, [consult_token_id]);

  const connectSocket = () => {
    dispatch(requestSocketConnect(consult_token_id));
  };

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.

  const renderMainContent = () => {
    if (roomState !== 'disconnected')
      return (
        <>
          <Room />
          <Controls />
        </>
      );
    if (hasDisconnected) return <PostConsult />;

    return <PreConsult width={width} height={height - TOOLBAR_HEIGHT} connectSocket={connectSocket} />;
  };

  const isUsingSupportedBrowser = () => {
    if (is.ios() && is.safari()) {
      return true;
    } else if (isChrome()) {
      return true;
    } else {
      return false;
    }
  };

  const handleBrowserDialogClose = () => {
    setShowSupportedBrowsers(false);
    setInitialized(true);
  };

  return (
    <Container style={{ height: height + 'px' }}>
      <BrowserSupportDialog
        open={showSupportedBrowsers}
        onNext={handleBrowserDialogClose}
        browsers={SUPPORTED_BROWSERS}
        recommended={is.ios() ? 'Safari' : 'Chrome'}
        buttonText="Would you like to continue anyway?"
      />
      {initialized ? (
        <React.Fragment>
          <MenuBar />
          <Main>{renderMainContent()}</Main>
        </React.Fragment>
      ) : null}
      <ReconnectingNotification />
    </Container>
  );
}

const isChromeFlavor = () => {
  return is.edge() || is.opera();
};

const isChrome = () => {
  return !isChromeFlavor() && is.chrome();
};

const SUPPORTED_BROWSERS = [
  {
    browser: 'ie',
    supported: false,
  },
  {
    browser: 'chrome',
    supported: is.not.ios() && !isChrome(),
  },
  {
    browser: 'safari',
    supported: is.ios(),
  },
  {
    browser: 'firefox',
    supported: false,
  },
  {
    browser: 'opera',
    supported: false,
  },
];
