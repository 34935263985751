import React from "react";

import useSound from "use-sound";

import { styled } from "@material-ui/core/styles";
import { Typography, Fade } from "@material-ui/core";
import PreConsultActionButton from "./PreConsultActionButton";


const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});



export default (props: { room: any }) => {

  // const [play] = useSound("/sound/join.mp3");
  //
  // React.useEffect(() => {
  //   play();
  // }, [play]);

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>

      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Waiting to start...</LightTitle>
        <Typography align='center'>{props.room.booking.practitioner_name} has been notified. Your consult will start automatically.</Typography>
        {/*<PreConsultActionButton*/}
          {/*type="submit"*/}
          {/*color="primary"*/}
          {/*variant="contained"*/}
          {/*// onClick={props.onReady}*/}
        {/*>*/}
          {/*I am ready*/}
        {/*</PreConsultActionButton>*/}
      </PreJoinContiner>
    </Fade>
  );
}