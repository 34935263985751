import { useCallback, useEffect, useState } from 'react';

import { ensureMediaPermissions, isMobile } from '../../../utils';
import Video, { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions } from 'twilio-video';
import { useSelector } from 'react-redux';
import { videoConstraints } from '../../../consts';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then(newTrack => {
        setTrack(newTrack);
        return newTrack;
      })
    );
  }, []);

  useEffect(() => {
    getLocalAudioTrack();
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack] as const;
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();
  const videoQuality: string = useSelector((state: any) => state.messageState.videoQuality);

  const getLocalVideoTrack = useCallback(
    (newOptions?: CreateLocalTrackOptions, videoQualitySetting: string = videoQuality) => {
      // In the DeviceSelector and FlipCameraButton components, a new video track is created,
      // then the old track is unpublished and the new track is published. Unpublishing the old
      // track and publishing the new track at the same time sometimes causes a conflict when the
      // track name is 'camera', so here we append a timestamp to the track name to avoid the
      // conflict.
      let selectedVideoConstraints: object = videoConstraints(videoQualitySetting);

      const options: CreateLocalTrackOptions = {
        ...selectedVideoConstraints,
        name: `camera-${Date.now()}`,
        ...newOptions,
      };

      return ensureMediaPermissions().then(() =>
        Video.createLocalVideoTrack(options).then(newTrack => {
          setTrack(newTrack);
          return newTrack;
        })
      );
    },
    []
  );

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack({}, videoQuality);
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack] as const;
}

export default function useLocalTracks() {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter(track => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
  )[];

  return { localTracks, getLocalVideoTrack, getLocalAudioTrack };
}
