import React from 'react';
import { disabledColor } from './consts';

const FireFox = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-label="Firefox" viewBox="0 0 512 512" {...props}>
      <path
        fill={props.disabled ? 'rgba(0,0,0,0)' : '#3D6DEB'}
        d="M400.588,127.614c-28.034-21.612-65.61-33.49-65.986-33.607c-1.853-0.577-3.07-2.353-2.938-4.292
	c0.133-1.937,1.58-3.532,3.495-3.852l28.214-4.719c-28.493-20.479-61.959-31.252-97.379-31.252
	c-48.26,0-93.555,20.529-125.493,56.594c5.437,0.559,8.719,1.079,10.636,1.454c4.457-3.359,17.39-12.413,34.066-18
	c20.481-6.782,33.018-5.529,33.441-5.484c1.727,0.186,3.162,1.42,3.606,3.1c0.445,1.68-0.193,3.461-1.603,4.477
	c-4.932,3.557-18.597,14.308-22.55,21.255c-3.651,6.352-2.728,13.795-2.342,16.004c1.865,2.637,10.058,13.375,22.493,17.508
	c5.969,1.977,12.578,2.805,18.409,3.533c10.189,1.277,18.99,2.379,22.336,9.158c2.525,4.98-0.635,11.537-24.221,28.684
	c-9.749,7.085-19.891,13.662-24.13,16.363l1.173,36.761c0.035,0.217,0.052,0.44,0.052,0.665c0,2.318-1.878,4.197-4.197,4.197
	c-0.013,0-0.025,0-0.032,0c-0.499,0-1-0.089-1.479-0.269c-0.086-0.032-8.855-3.283-16.747-3.283c-8.895,0-10.359,3.821-10.617,7.56
	c-0.569,8.2,3.297,17.81,10.085,25.087c5.046,5.405,13.855,11.851,27.175,11.849c3.78,0,7.72-0.539,11.71-1.605
	c21.74-5.885,36.916-9.748,49.156-9.748c7.693,0,14.045,1.504,19.985,4.734c11.977,6.595,21.676,20.973,19.603,29.042
	c-0.846,3.285-3.544,5.328-7.042,5.328c-6.622,0-15.242,0.782-33.782,10.105c-3.08,1.524-6.281,3.317-9.67,5.214
	c-12.9,7.226-28.954,16.217-49.828,16.217c-1.239,0-2.486-0.032-3.742-0.096c14.572,10.142,40.278,24.576,68.569,24.575
	c7.987,0,15.794-1.158,23.207-3.441c53.079-16.474,72.46-58.085,72.648-58.503c0.838-1.855,2.886-2.839,4.86-2.335
	c1.972,0.502,3.298,2.347,3.149,4.374l-1.563,21.15c4.076-6.26,8.824-15.745,11.858-28.941c6.411-28.081,2.602-60.384,2.562-60.707
	c-0.223-1.835,0.779-3.599,2.469-4.344c1.694-0.749,3.669-0.3,4.875,1.099l11.181,12.974c1.852-12.511,4.786-35.375,3.93-49.673
	c-1.242-19.108-6.936-26.562-6.993-26.638c-1.309-1.665-1.185-4.069,0.311-5.57c1.494-1.504,3.857-1.675,5.533-0.376l9.434,7.33
	c-2.593-10.496-6.254-20.837-10.933-30.865C412.533,138.82,406.828,132.495,400.588,127.614z"
      />
      <path
        fill={props.disabled ? disabledColor : '#DD512A'}
        d="M506.905,174.371c-1.86-0.285-3.683,0.705-4.458,2.419l-5.729,12.684
	c-2.175-16.67-6.211-43.501-11.54-61.095c-8.123-27.089-25.325-44.602-26.054-45.336c-1.318-1.323-3.349-1.618-4.99-0.723
	c-1.638,0.898-2.488,2.768-2.081,4.593l4.452,20.056c-10.711-11.188-27.919-27.249-44.946-35.078
	c-4.094-1.998-8.255-3.826-11.9-5.405c-36.993-32.86-84.434-50.949-133.662-50.949c-69.326,0-132.509,34.729-169.919,93.141
	c-3.52-2.844-8.455-7.817-11.284-14.988c-5.143-12.61-7.748-26.774-7.775-26.916c-0.277-1.519-1.365-2.765-2.835-3.243
	c-1.467-0.475-3.082-0.106-4.198,0.964c-30.829,29.565-27.769,75.651-26.619,86.097c-3.821,4.081-14.619,16.18-25.528,33.634
	C4.29,205.916,0.189,243.832,0.023,245.435c-0.186,1.789,0.786,3.498,2.419,4.249c1.64,0.754,3.569,0.378,4.801-0.928l9.023-9.531
	c-0.984,3.649-1.974,7.806-2.889,12.462c-4.064,20.103-2.875,51.283-2.823,52.601c0.077,1.93,1.462,3.559,3.357,3.945
	c1.893,0.389,3.805-0.574,4.631-2.32l5.309-11.242c3.915,27.365,18.943,85.589,76.568,139.224
	c42.68,39.758,102.042,62.562,162.867,62.565c0.007,0,0.008,0,0.015,0c59.033,0,114.773-21.114,161.183-61.056
	c54.877-47.276,75.321-114.791,82.806-163.103c8.081-52.158,3.35-92.587,3.144-94.284
	C510.21,176.146,508.768,174.657,506.905,174.371z M419.048,169.945c-1.675-1.301-4.039-1.128-5.533,0.376
	c-1.496,1.502-1.62,3.904-0.311,5.57c0.057,0.076,5.751,7.53,6.993,26.638c0.856,14.298-2.078,37.163-3.93,49.673l-11.181-12.974
	c-1.207-1.4-3.183-1.85-4.875-1.099c-1.69,0.745-2.692,2.51-2.469,4.344c0.039,0.322,3.849,32.627-2.562,60.707
	c-3.035,13.195-7.784,22.681-11.858,28.941l1.563-21.15c0.149-2.028-1.177-3.874-3.149-4.374c-1.974-0.505-4.022,0.48-4.86,2.335
	c-0.188,0.418-19.569,42.027-72.648,58.503c-7.413,2.281-15.22,3.441-23.207,3.441c-28.291,0.002-53.997-14.433-68.569-24.575
	c1.254,0.064,2.501,0.096,3.742,0.096c20.873,0,36.926-8.992,49.828-16.217c3.389-1.898,6.59-3.69,9.67-5.214
	c18.54-9.323,27.16-10.105,33.782-10.105c3.498,0,6.196-2.043,7.042-5.328c2.073-8.069-7.626-22.446-19.603-29.042
	c-5.941-3.23-12.292-4.734-19.986-4.734c-12.24,0-27.417,3.862-49.156,9.748c-3.99,1.066-7.93,1.605-11.71,1.605
	c-13.321,0.002-22.131-6.444-27.175-11.849c-6.788-7.277-10.654-16.888-10.085-25.087c0.258-3.74,1.722-7.56,10.617-7.56
	c7.891,0,16.662,3.253,16.747,3.283c0.478,0.18,0.98,0.269,1.479,0.269c0.008,0,0.02,0,0.032,0c2.318,0,4.197-1.878,4.197-4.197
	c0-0.227-0.018-0.45-0.052-0.665l-1.173-36.761c4.238-2.701,14.381-9.278,24.13-16.363c23.586-17.147,26.745-23.704,24.221-28.684
	c-3.344-6.78-12.145-7.881-22.336-9.158c-5.831-0.73-12.44-1.558-18.409-3.533c-12.435-4.133-20.628-14.869-22.493-17.508
	c-0.386-2.209-1.309-9.652,2.342-16.004c3.951-6.946,17.617-17.698,22.55-21.255c1.41-1.016,2.048-2.797,1.603-4.477
	c-0.445-1.68-1.878-2.912-3.606-3.1c-0.423-0.045-12.961-1.298-33.441,5.484c-16.675,5.586-29.609,14.641-34.066,18
	c-1.917-0.376-5.199-0.895-10.636-1.454c31.935-36.063,77.233-56.594,125.493-56.594c35.42,0,68.887,10.773,97.379,31.252
	l-28.214,4.719c-1.917,0.321-3.362,1.915-3.495,3.852s1.084,3.715,2.938,4.292c0.376,0.118,37.95,11.995,65.986,33.607
	c6.239,4.881,11.943,11.206,16.961,18.797c4.678,10.028,8.339,20.368,10.933,30.865L419.048,169.945z"
      />
    </svg>
  );
};
export default FireFox;
