import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import socketReducer from './socket/reducer';
import messageReducer from './message/reducer';
import socketMiddleware from './socket/middleware';
import messageMiddleware from './message/middleware';

const rootReducer = combineReducers({
  socketState: socketReducer,
  messageState: messageReducer
});


const featureMiddleware: any[] = [socketMiddleware, messageMiddleware];
const coreMiddleware: any[] = [];


// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const index = {
  ...createStore(rootReducer, composeEnhancers(applyMiddleware(...featureMiddleware, ...coreMiddleware)))
};

// @ts-ignore
window.store = index;

export default index;