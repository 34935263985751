import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import throttle from "lodash/throttle";
import { API_ROOT_URL } from "../../consts";


const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

const searchICD10 = async (code) => {

  const endpoint = `${API_ROOT_URL}/search-icd10`;

  const params = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code })
  };

  const result = await fetch(endpoint, params).then((res) => {
    if (!res.ok) {
      console.log("failed to fetch ICD 10 Codes, returning empty array");
      return [];
    }
    return res.json();
  });

  return result.results || [];

};


export default function ICD10Selector(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const search = React.useMemo(
    () =>
      throttle(async (request, callback) => {
        const results = await searchICD10(request.input);

        setOptions(results);
      }, 500),
    []
  );

  React.useEffect(() => {
    let active = true;


    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    search({ input: inputValue }, (results) => {
      if (active) {
        setValue(null);
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      fullWidth
      freeSolo
      multiple
      getOptionLabel={(option) => (typeof option === "string" ? option : option.formatted_code)}
      filterOptions={(x) => x.filter((x) => x.code !== undefined)}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      getOptionSelected={(a,b) => a.code === b.code}
      onChange={(event, newValue) => {
        setValue(newValue);

        if (props.onSelect) {
          props.onSelect(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Select ICD10 Code(s)" variant="outlined" fullWidth/>
      )}
      popupIcon={null}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <span style={{ fontWeight: 700 }}>
               {option.formatted_code}
              </span>

              <Typography variant="body2" color="textSecondary">
                {option.description}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
