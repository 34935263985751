import React from 'react';
import { disabledColor } from './consts';

const Opera = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-label="Opera" viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            fill={props.disabled ? disabledColor : '#ff0059'}
            d="M116,256c0-96.946,37.606-199.057,113.792-254.5C101.276,14.476,0,122.849,0,256c0,133.07,101.206,241.517,229.792,254.5
			C153.293,454.83,116,352.531,116,256z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill={props.disabled ? disabledColor : '#d20041'}
            d="M291.658,2.64C241.115,19.507,198.4,63.906,172.55,123.497c23.962-21.023,52.914-32.384,83.45-32.384
			c78.832,0,140,75.365,140,164.886c0,89.524-61.173,164.886-140,164.886c-30.536,0-59.488-11.361-83.45-32.384
			c25.85,59.591,68.565,103.99,119.108,120.857C414.206,492.395,512,387.196,512,256C512,124.775,414.179,19.601,291.658,2.64z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Opera;
