import React, { createContext, useContext, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { User } from 'firebase';
import { API_ROOT_URL } from './../consts';

interface TokenResponse {
  token: string;
  type: string;
}


export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getTokenCustom(consult_token_id: string): Promise<TokenResponse>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  userType: string;
  setUserType(type: string): void;
  userToken: string;
  setUserToken(type: string): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [userType, setUserType] = useState('');
  const [userToken, setUserToken] = useState('');

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
  } as StateContextType;


  contextValue = {
    ...contextValue,
    userType,
    setUserType,
    userToken,
    setUserToken,
    getTokenCustom: async consult_token_id => {
      const headers = new window.Headers();
      const endpoint = `${API_ROOT_URL}/token_v2/${consult_token_id}`;
      return fetch(endpoint, { headers }).then((res) => {
        if (!res.ok) {
          if (res.status === 404)
            throw Error("Token is invalid or expired. Please make sure you are using the correct link.");

          throw Error("Could not validate your token. Please try again or contact support.")
        }
        return res.json()
      });
    },
  };

  const getTokenCustom: StateContextType['getTokenCustom'] = consult_token_id => {
    setIsFetching(true);
    return contextValue
      .getTokenCustom(consult_token_id)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getTokenCustom }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
