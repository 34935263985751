import React from "react";

import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";


export default styled(Button)({
  borderRadius: 25,
  padding: "15px 25px",
  margin: "1em",
  fontWeight: 400,
  textTransform: "none"
});
