import {
  CONNECTION_CHANGED,
  MESSAGE_RECEIVED,
  START_CONSULT,
  sendMessage,
  updateRoomStatus,
  connectedFromAnotherDevice,
  setConsultStarted,
  GET_MEDIA_PERMISSIONS,
  INIT_VIDEO,
} from '../actions';
import { requestSocketDisonnect } from '../../socket/actions';
import { getDevices, getPermissions } from './helpers';

export const ROOM_STATUS = 'ROOM_STATUS';
export const CONNECTED_FROM_ANOTHER_DEVICE = 'CONNECTED_FROM_ANOTHER_DEVICE';

const messageMiddleware = (store: any) => {
  let interval: number = 0;

  return (next: any) => (action: any) => {
    switch (action.type) {
      case GET_MEDIA_PERMISSIONS: {
        getPermissions(store.dispatch);
        break;
      }
      case INIT_VIDEO: {
        getDevices();
        break;
      }
      case CONNECTION_CHANGED:
        if (action.connected) {
          const poll = () => store.dispatch(sendMessage({ action: 'status' }));

          clearInterval(interval);
          interval = <any>setInterval(poll, 15 * 1000);
          poll();
        } else {
          clearInterval(interval);
        }

        break;

      case MESSAGE_RECEIVED:
        const { message } = action;
        const { type, payload } = message;

        if (!type) break;

        if (type === ROOM_STATUS) {
          store.dispatch(updateRoomStatus(payload));
        } else if (type === CONNECTED_FROM_ANOTHER_DEVICE) {
          store.dispatch(connectedFromAnotherDevice());
          store.dispatch(requestSocketDisonnect());
        }
        break;

      case START_CONSULT:
        store.dispatch(setConsultStarted(true));
        store.dispatch(sendMessage({ action: 'start-consult' }));
        break;
      default:
        break;
    }

    return next(action);
  };
};

export default messageMiddleware;
