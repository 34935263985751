import React from 'react';

import StarRatings from 'react-star-ratings';
import { useParams } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styled, MuiThemeProvider } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';

import theme from './theme';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { API_ROOT_URL } from '../../consts';
import ICD10Dialog from '../ICD10Dialog/ICD10Dialog';
import { useSelector } from 'react-redux';

export default function Room() {
  const { userType } = useAppState();
  const booking: any = useSelector((s: any) => s.messageState.room.booking);

  const [rating, setRating] = React.useState<number>(0);
  const [textFeedback, setTextFeedback] = React.useState<string>('');
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState<boolean>(false);
  const { setHasDisconnected } = useVideoContext();
  const [question, setQuestion] = React.useState<string>('');
  const [scale, setScale] = React.useState<string[]>(['', '']);
  const [showICD10Dialog, setShowICD10Dialog] = React.useState<boolean>(false);

  const { consult_token_id } = useParams();
  const autoPayment = booking?.auto_payment;

  React.useEffect(() => {
    if (userType === 'PRACTITIONER') {
      setQuestion('How was the audio and video?');
      setScale(['Very bad', 'Very good']);

      if (autoPayment) {
        setShowICD10Dialog(true);
      }
    } else if (userType === 'PATIENT') {
      setQuestion('How likely are you to recommend this service to your friends and family?');
      setScale(['Unlikely', 'Very likely']);
    }
  }, [userType, autoPayment]);

  const saveRating = (rating: number, textFeedback: string = '') => {
    const endpoint = `${API_ROOT_URL}/save-rating`;
    let payload = {
      token: consult_token_id,
      rating: rating,
      text_feedback: textFeedback,
    };

    const params = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    };
    fetch(endpoint, params).then(res => {
      if (!res.ok) {
        console.log('failed to save rating - ignoring (best effort attempt)');
      }
      return;
    });
  };

  const handleRatingChange = (rating: number) => {
    setRating(rating);
    if (rating > 3) {
      saveRating(rating);
      setFeedbackSubmitted(true);
    }
  };

  const handleSubmit = () => {
    saveRating(rating, textFeedback);
    setFeedbackSubmitted(true);
  };

  const renderRatingCardContent = () => {
    return (
      <Grid>
        <ICD10Dialog dismiss={() => setShowICD10Dialog(false)} show={showICD10Dialog} />

        <Fade in={feedbackSubmitted} timeout={{ enter: 2000, exit: 0 }}>
          <Question variant="h6" align="center" gutterBottom>
            Thank you for your feedback!
          </Question>
        </Fade>

        <Fade in={rating <= 3 && !feedbackSubmitted} timeout={{ enter: 2000, exit: 0 }}>
          <RatingCard raised>
            <CardContent>
              <Question variant="h6" align="center" gutterBottom>
                {question}
              </Question>

              <RatingContainer>
                <StarRatings
                  rating={rating}
                  starRatedColor="#1D3443"
                  starHoverColor="#1D3443"
                  changeRating={handleRatingChange}
                  numberOfStars={5}
                  starDimension="40px"
                  name="rating"
                />
              </RatingContainer>

              <HintTextContainer>
                <HintText>{scale[0]}</HintText>
                <div />
                <HintText>{scale[1]}</HintText>
              </HintTextContainer>
              {rating > 0 && rating <= 3 ? (
                <>
                  <Instruction align="center" gutterBottom>
                    <p>We value your feedback</p>
                    <p>Please let us know more about your experience below</p>
                  </Instruction>
                  <TextFeedback align="center" gutterBottom>
                    <TextField
                      multiline
                      fullWidth
                      value={textFeedback}
                      onChange={e => setTextFeedback(e.target.value)}
                      placeholder="Add your feedback"
                      rows={4}
                      variant="outlined"
                    />
                  </TextFeedback>
                  <SubmitButtonsContainer>
                    <Button color="primary" variant="outlined" onClick={handleSubmit}>
                      SUBMIT
                    </Button>
                  </SubmitButtonsContainer>
                </>
              ) : null}
            </CardContent>
          </RatingCard>
        </Fade>
      </Grid>
    );
  };
  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <Typography variant="h1" component="h1" align="center">
          You've left the consult
        </Typography>
        <ButtonsContainer>
          <Button color="primary" variant="outlined" onClick={() => setHasDisconnected(false)}>
            Rejoin
          </Button>
        </ButtonsContainer>

        {renderRatingCardContent()}
      </Container>
    </MuiThemeProvider>
  );
}

const Container = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'grid',
  backgroundColor: theme.palette.background.paper,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: 'auto auto auto minmax(0, 1fr)',
  gridGap: '6px',
  padding: '20px',
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  margin: '1em',
}));

const SubmitButtonsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  marginTop: '2em',
}));

const RatingCard = styled(Card)(({ theme }) => ({
  marginTop: '1em',
  maxWidth: '375px',
  minWidth: '300px',
  justifySelf: 'center',
  padding: 15,
}));

const HintTextContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto minmax(0, 1fr) auto',
  width: '100%',
}));

const HintText = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
}));

const Question = styled(Typography)(({ theme }) => ({
  marginBottom: '1em',
}));

const Instruction = styled(Typography)(({ theme }) => ({
  marginBottom: '1em',
}));

const TextFeedback = styled(Typography)(({ theme }) => ({
  marginTop: '1em',
}));

const RatingContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'center',
  width: '100%',
  margin: 0,
  padding: 0,
}));
