import {
  UPDATE_ROOM_STATUS,
  SET_TOKEN,
  CONNECTED_FROM_ANOTHER_DEVICE,
  SET_IS_READY,
  SET_CONSULT_STARTED,
  SET_PERMISSION_STEP,
  SET_CONSULT_STATE,
  CHANGE_LOCAL_VIDEO_QUALITY,
} from '../actions';
import { CONNECTION_CHANGED } from '../../socket/actions';

const INITIAL_STATE = {
  token: null,
  room: null,
  connectedFromAnotherDevice: false,
  isReady: false,
  consultStarted: false,
  permissionStep: 'unknown',
  consultState: 'request-permission',
  videoQuality: 'medium',
};

const messageReducer = (state = INITIAL_STATE, action: { type: string; payload: any; connected?: boolean }) => {
  switch (action.type) {
    case CONNECTION_CHANGED:
      if (action.connected) {
        return { ...state, connectedFromAnotherDevice: false };
      }
      return { ...state };

    case SET_PERMISSION_STEP:
      return { ...state, permissionStep: action.payload };

    case CONNECTED_FROM_ANOTHER_DEVICE:
      return { ...state, room: null, connectedFromAnotherDevice: true };

    case SET_TOKEN:
      return { ...state, token: action.payload };

    case UPDATE_ROOM_STATUS:
      return { ...state, room: action.payload };

    case SET_IS_READY:
      return { ...state, isReady: action.payload };

    case SET_CONSULT_STARTED:
      return { ...state, consultStarted: action.payload };
    case SET_CONSULT_STATE:
      return { ...state, consultState: action.payload };
    case CHANGE_LOCAL_VIDEO_QUALITY:
      return { ...state, videoQuality: action.payload };

    default:
      return state;
  }
};

export default messageReducer;
