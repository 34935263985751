import React from "react";
import { LocalVideoTrack } from "twilio-video";
import VideoTrack from "../VideoTrack/VideoTrack";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

import { styled } from "@material-ui/core/styles";


const VideoContainer = styled("div")({
  display: "grid",
  padding: 0,
  gridTemplateRows: "1fr",
  gridTemplateColumns: "1fr",
  position: "relative",
  borderRadius: 8,
  overflow: 'hidden',
});

export default function LocalVideoPreview(props: React.PropsWithChildren<{maxWidth: number, maxHeight: number}>) {
  const { localTracks } = useVideoContext();
  const [videoContainerWidth, setVideoContainerWidth] = React.useState<number>(0);
  const [videoContainerHeight, setVideoContainerHeight] = React.useState<number>(0);


  const videoTrack = localTracks.find(track => track.name.includes("camera")) as LocalVideoTrack;

  const updateDimensions = (track: any) => {

    let _width = props.maxWidth;
    let _height = props.maxHeight;

    if (track) {
      let videoHeight = track.dimensions.height;
      let videoWidth = track.dimensions.width;

      if (videoHeight !== null && videoWidth !== null) {
        let requiredScaling = Math.min(props.maxHeight / videoHeight, props.maxWidth / videoWidth);
        _width = Math.floor(videoWidth * requiredScaling);
        _height = Math.floor(videoHeight * requiredScaling);
      }
    }

    setVideoContainerWidth(_width);
    setVideoContainerHeight(_height);

  };

  React.useEffect(() => {
    updateDimensions(videoTrack);

    if (videoTrack) {
      videoTrack.on("started", updateDimensions);
      videoTrack.on("dimensionsChanged", updateDimensions);

      return () => {
        videoTrack.off('started', updateDimensions);
        videoTrack.off('dimensionsChanged', updateDimensions);
      }
    }
  }, [videoTrack, props.maxHeight, props.maxWidth]);

  return (
    <VideoContainer style={{ width: videoContainerWidth + "px", height: videoContainerHeight + "px" }}>
      {videoTrack && <VideoTrack track={videoTrack} isLocal/>}
      {
        props.children
      }
    </VideoContainer>);
}
