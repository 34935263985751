const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

export default class Socket {
  private readonly onChange: (isConnected: boolean) => void;
  private readonly onMessage: (message: object) => void;
  private readonly onDisconnect: () => void;
  private socket: any;

  constructor(onChange: (isConnected: boolean) => void, onMessage: (message: object) => void, onDisconnect: () => void) {
    this.onChange = onChange;
    this.onMessage = onMessage;
    this.onDisconnect = onDisconnect;
    this.socket = null;
  }

  public connect = (url: string) => {

    if (this.socket && (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)) {
      return;
    }

    this.socket = new WebSocket(url);

    this.socket.onopen = (event: Event) => {
      this.onChange(true);
    };

    this.socket.onclose = (event: Event) => {
      this.onChange(false);
      this.onDisconnect();
    };

    this.socket.onmessage = (event: MessageEvent) => {
      this.onMessage(JSON.parse(event.data));
    };

  };


  public sendMessage = (message: object) => {

    if (this.socket.readyState === SOCKET_OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('Cannot emit socket messages. WebSocket not connected.');
    }

  };

  public disconnect = () => this.socket.close();
}
