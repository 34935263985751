import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, styled } from '@material-ui/core/styles';

import App from './App';
import AddToCalender from './AddToCalender.js';

import store from './redux';

import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectOptions } from 'twilio-video';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import { isMobile } from './utils';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useHeight from './hooks/useHeight/useHeight';
import RequestMediaPermissions from './components/PreConsult/RequestMediaPermissions';
import SimpleMenuBar from './components/MenuBar/SimpleMenuBar';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
let connectionOptions: ConnectOptions = {
  // Bandwidth Profile, Dominant Speaker, and Network Quality
  // features are only available in Small Group or Group Rooms.
  // Please set "Room Type" to "Group" or "Small Group" in your
  // Twilio Console: https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: isMobile ? 90 : 1080, width: isMobile ? 160 : 1920 },
        standard: { height: isMobile ? 90 : 720, width: isMobile ? 160 : 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  networkQuality: { local: 1, remote: 1 },

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  // VP8 simulcast enables the media server in a Small Group or Group Room
  // to adapt your encoded video quality for each RemoteParticipant based on
  // their individual bandwidth constraints. This has no effect if you are
  // using Peer-to-Peer Rooms.
  // preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
};

// // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 500000;
}

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

const VideoApp = () => {
  const { error, setError } = useAppState();
  const consultState = useSelector((state: any) => state.messageState.consultState);
  const height = useHeight();

  if (consultState === 'request-permission')
    return (
      <Container style={{ height: height + 'px' }}>
        <SimpleMenuBar />
        <Main>
          <RequestMediaPermissions />
        </Main>
      </Container>
    );

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <App />
    </VideoProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppStateProvider>
          <Switch>
            <Route path="/cal/:consult_token_id">
              <AddToCalender />
            </Route>
            <Route path="/:consult_token_id">
              <VideoApp />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
