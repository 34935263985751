import React from "react";

import useSound from "use-sound";

import { styled } from "@material-ui/core/styles";
import { Typography, Fade } from "@material-ui/core";
import PreConsultActionButton from "./PreConsultActionButton";


const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});


type OnStartConsultFunction = () => void;

export default (props: { room: any, onStartConsult: OnStartConsultFunction }) => {

  const [play] = useSound("/sound/join.mp3");
  const [text, setText] = React.useState<string>("");


  React.useEffect(() => {
    if (props.room.patient_ready) {
      play();
      setText(`${props?.room?.booking?.patient_name || props?.room?.booking_summary?.patient_name} is ready`);
    } else {
      setText(`${props?.room?.booking?.patient_name || props?.room?.booking_summary?.patient_name} has not yet joined.`);
    }
  }, [props.room.patient_ready]);

  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>

      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Waiting to start...</LightTitle>
        <Typography>{text}</Typography>
        {!(props?.room?.patient_ready) && <Typography>An SMS has been sent to the patient</Typography>}
        <PreConsultActionButton
          type="submit"
          color="secondary"
          variant="contained"
          disabled={!props.room.patient_ready}
          onClick={props.onStartConsult}
        >
          Start consult
        </PreConsultActionButton>
      </PreJoinContiner>
    </Fade>
  );
}