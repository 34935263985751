import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import BrowserSupport from '../BrowserSupport/BrowserSupport';

const BrowserSupportDialog = (props: any) => {
  const classes = useStyles();

  return (
    <Dialog open={props.open}>
      <DialogTitle>You seem to be using an unsupported browser</DialogTitle>
      <DialogContent>
        <DialogContentText>
          For this device, we recommend using <strong>{props.recommended}</strong>
        </DialogContentText>
        <BrowserSupport className={classes.browserSupport} browsers={props.browsers} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.onNext}>
          {props.buttonText || 'next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  browserSupport: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: `${theme.spacing(1)}px`,
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

export default BrowserSupportDialog;
