const getRootUrl = stage => {
  if (stage === 'dev') return 'https://telehealth-api-dev.netcaretech.co.za';
  else if (stage === 'staging') return 'https://telehealth-api-staging.netcaretech.co.za';
  else if (stage === 'prod') return 'https://telehealth-api-prod.netcaretech.co.za';

  // Default
  return 'http://localhost:8888';
};

const getWebsocketUrl = stage => {
  if (stage === 'dev') return 'wss://telehealth-ws-dev.netcaretech.co.za';
  else if (stage === 'staging') return 'wss://telehealth-ws-staging.netcaretech.co.za';
  else if (stage === 'prod') return 'wss://telehealth-ws-prod.netcaretech.co.za';

  // Default
  return 'wss://telehealth-ws-dev.netcaretech.co.za';
};

export const STAGE = process.env.REACT_APP_STAGE;
export const API_ROOT_URL = getRootUrl(STAGE);
export const WEBSOCKET_ROOT_URL = getWebsocketUrl(STAGE);

export const videoConstraints = mode => {
  if (mode === 'high')
    return {
      frameRate: 30,
      height: { min: 480, ideal: 720, max: 720 },
      width: { min: 640, ideal: 1280, max: 1280 },
    };
  if (mode === 'medium')
    return {
      width: { min: 160, ideal: 320, max: 320 },
      height: { min: 90, ideal: 240, max: 240 },
      frameRate: { max: 24 },
    };
  return {
    width: { min: 160, ideal: 160, max: 160 },
    height: { min: 90, ideal: 90, max: 90 },
    frameRate: { max: 24 },
  };
};
