import React, { PropsWithChildren } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import ICD10Selector from "./ICD10Selector";
import { API_ROOT_URL } from "../../consts";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icd10selection: {
    fontSize: '0.8em'
  }
}));

interface ErrorDialogProps {
  dismiss: Function;
  show: boolean;
}


interface ICD10Code {
  code: string;
  formatted_code?: string | null;
  description: string;
}

function ICD10Dialog({ dismiss, show }: PropsWithChildren<ErrorDialogProps>) {
  const classes = useStyles();

  const [codes, setCodes] = React.useState<ICD10Code[]>([]);
  const [requestPending, setRequestPending] = React.useState<boolean>(false);

  const { consult_token_id } = useParams();

  const saveICD10Codes = (codes: ICD10Code[]) => {

    setRequestPending(true);

    const endpoint = `${API_ROOT_URL}/save-icd10`;
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: consult_token_id, codes: codes.map((c) => c.formatted_code) })
    };
    fetch(endpoint, params).then((res) => {
      setRequestPending(false);
      if (!res.ok) {
        console.log("failed to save rating - ignoring (best effort attempt)");
      } else {
        dismiss();
      }
      return;
    }).catch((err) => {
      setRequestPending(false);
    });
  };

  return (
    <Dialog open={show} onClose={() => dismiss()} fullWidth={true} maxWidth="xs">
      <DialogTitle>ICD10 Code Required</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ paddingBottom: 20 }}>This patient requires an invoice for the consult. Please
          capture the ICD10 Code for the consult and it will be sent to the patient automatically.</DialogContentText>
        <ICD10Selector onSelect={(c: ICD10Code[]) => setCodes(c)}/>
        <hr />

        {
          codes.map((code: ICD10Code) => <DialogContentText className={classes.icd10selection} key={code.code}><b>{code.formatted_code}</b> - {code.description}</DialogContentText>)
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismiss()}>
          Do this later
        </Button>
        <Button onClick={() => saveICD10Codes(codes)}
                color="secondary"
                variant="contained"
                autoFocus
                disabled={codes.length === 0 || requestPending}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ICD10Dialog;
