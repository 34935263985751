import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Chrome from './icons/Chrome';
import FireFox from './icons/FireFox';
import InternetExporer from './icons/InternetExplorer';
import Safari from './icons/Safari';
import Opera from './icons/Opera';

type browserMapping = {
  [key: string]: React.FC;
};

const BROWSERS: browserMapping = {
  chrome: Chrome,
  firefox: FireFox,
  ie: InternetExporer,
  safari: Safari,
  opera: Opera,
};

const BrowserSupport = (props: any) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={props.className}>
      {props.browsers.map((item: any) => {
        const BrowserComponent = getBrowserComponent(item.browser);
        return <BrowserComponent key={item.browser} disabled={!item.supported} height={matches ? '64' : '40'} />;
      })}
    </div>
  );
};

const getBrowserComponent = (browserName: string): any => {
  return BROWSERS[browserName];
};

export default BrowserSupport;
