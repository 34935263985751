export const INIT_VIDEO = 'INIT_VIDEO';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SET_TOKEN = 'SET_TOKEN';
export const CONNECTION_CHANGED = 'CONNECTION_CHANGED';
export const UPDATE_ROOM_STATUS = 'UPDATE_ROOM_STATUS';
export const CONNECTED_FROM_ANOTHER_DEVICE = 'CONNECTED_FROM_ANOTHER_DEVICE';
export const SET_IS_READY = 'SET_IS_READY';
export const START_CONSULT = 'START_CONSULT';
export const SET_CONSULT_STARTED = 'SET_CONSULT_STARTED';
export const GET_MEDIA_PERMISSIONS = 'GET_MEDIA_PERMISSIONS';
export const SET_PERMISSION_STEP = 'SET_PERMISSION_STEP';
export const SET_CONSULT_STATE = 'SET_CONSULT_STATE';
export const CHANGE_LOCAL_VIDEO_QUALITY = 'CHANGE_LOCAL_VIDEO_QUALITY';

export const messageReceived = (message: object) => {
  return {
    type: MESSAGE_RECEIVED,
    message,
  };
};

export const sendMessage = (message: object) => {
  return {
    type: SEND_MESSAGE_REQUEST,
    message,
  };
};

export const setToken = (token: string) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const updateRoomStatus = (status: object) => ({ type: UPDATE_ROOM_STATUS, payload: status });
export const connectedFromAnotherDevice = () => ({ type: CONNECTED_FROM_ANOTHER_DEVICE });
export const setIsReady = (ready: boolean) => ({ type: SET_IS_READY, payload: ready });
export const startConsult = () => ({ type: START_CONSULT });
export const setConsultStarted = (started: boolean) => ({ type: SET_CONSULT_STARTED, payload: started });

export const getMediaPermissions = () => ({ type: GET_MEDIA_PERMISSIONS });
export const setPermissionStep = (step: string) => ({ type: SET_PERMISSION_STEP, payload: step });
export const setConsultState = (newState: string) => ({ type: SET_CONSULT_STATE, payload: newState });
export const initVideo = () => ({ type: INIT_VIDEO });
export const changeLocalVideoQuality = (newQuality: string) => ({
  type: CHANGE_LOCAL_VIDEO_QUALITY,
  payload: newQuality,
});
