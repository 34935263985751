import React from "react";

import useSound from "use-sound";

import { styled } from "@material-ui/core/styles";
import { Typography, Slide } from "@material-ui/core";
import PreConsultActionButton from "./PreConsultActionButton";


const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});


type OnReadyFunction = () => void;

const getText = (room: any) => {
  if (room === null) return;

  if (room.user_type === 'practitioner') {
    if (room.patient_ready) {
      return `${room.booking.patient_name} is ready`;
    } else {
      return `${room.booking.patient_name} has not yet joined`;
    }
  }

  if (room.user_type === 'patient') {
    if (room.practitioner_ready) {
      return `Your consult is with ${room.booking.practitioner_name}`;
    } else {
      return `Your consult is with ${room.booking.practitioner_name}`;
    }
  }

  return;
};

export default (props: { onReady: OnReadyFunction, room: object }) => {

  // const [play] = useSound("/sound/join.mp3");
  //
  // React.useEffect(() => {
  //   play();
  // }, [play]);

  return (
    <Slide in={true} direction='down' timeout={{ enter: 500, exit: 0 }}>

      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Ready to join?</LightTitle>
        <Typography>{getText(props.room)}</Typography>
        <PreConsultActionButton
          type="submit"
          color="primary"
          variant="contained"
          onClick={props.onReady}
        >
          I am ready
        </PreConsultActionButton>
      </PreJoinContiner>
    </Slide>
  );
}