import React, { useRef, useEffect, useState, useCallback } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';
import useHeight from '../../hooks/useHeight/useHeight';
import { TOOLBAR_HEIGHT } from '../MenuBar/MenuBar';

const Video = styled('video')({
  maxHeight: '100%',
  maxWidth: '100%',
  objectFit: 'contain',
  gridRow: 1,
  gridColumn: 1,
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const [trackWidth, setTrackWidth] = useState<number | null>(0);
  const [trackHeight, setTrackHeight] = useState<number | null>(0);
  const [isPortrait, setIsPortrait] = useState(false);
  const height = useHeight();

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  const setTrackDimensions = useCallback(() => {
    setTrackWidth(track.dimensions.width || 0);
    setTrackHeight(track.dimensions.height || 0);
  }, [track]);

  useEffect(() => {
    setTrackDimensions();
    track.once('started', setTrackDimensions);
    track.on('dimensionsChanged', setTrackDimensions);
    return () => {
      track.off('started', setTrackDimensions);
      track.off('dimensionsChanged', setTrackDimensions);
    };
  }, [track, setTrackDimensions]);

  useEffect(() => {
    if (!trackWidth || !trackHeight) return;
    setIsPortrait(trackHeight > trackWidth);
  }, [trackWidth, trackHeight]);

  // The local video track is mirrored.
  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const videoHeight = isLocal ? '100%' : height - TOOLBAR_HEIGHT;
  const videoWidthStyle = isPortrait ? { width: 'auto', height: videoHeight } : { width: '100%', height: 'auto' };
  const style =
    isLocal && isFrontFacing ? { ...videoWidthStyle, transform: 'rotateY(180deg)' } : { ...videoWidthStyle };
  return <Video ref={ref} style={style} />;
}
