import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocalVideoQuality } from '../../redux/message/actions';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';

export default function VideoQualityButton() {
  const videoQuality = useSelector((state: any) => state.messageState.videoQuality);
  const dispatch = useDispatch();
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    hasDisconnected,
  } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  const localVideoInputDeviceId = localVideoTrack?.mediaStreamTrack.getSettings().deviceId;

  function replaceTrack(newDeviceId: string, newVideoQuality: string) {
    localVideoTrack?.stop();
    getLocalVideoTrack({ deviceId: { exact: newDeviceId } }, newVideoQuality).then(newTrack => {
      if (localVideoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(localVideoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
      }

      localParticipant?.publishTrack(newTrack);
    });
  }

  const changeVideoQuality = () => {
    let newQuality;
    if (videoQuality === 'high') newQuality = 'medium';
    else if (videoQuality === 'medium') newQuality = 'low';
    else newQuality = 'high';
    dispatch(changeLocalVideoQuality(newQuality));

    if (localVideoInputDeviceId) {
      replaceTrack(localVideoInputDeviceId, newQuality);
    }
  };

  if (!localVideoInputDeviceId || hasDisconnected) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        color: 'black',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ fontSize: '0.7em' }}>Set Data Usage:</div>
      <Button onClick={changeVideoQuality} variant={'outlined'} style={{ width: '60px' }}>
        {videoQuality === 'medium' ? 'med' : videoQuality}
      </Button>
    </div>
  );
}
