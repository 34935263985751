import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useComponentWillMount } from '../../hooks/useComponentWillMount';
import { getMediaPermissions, initVideo, setConsultState } from '../../redux/message/actions';
import PreConsultActionButton from './Instructions/PreConsultActionButton';
import styled from '@material-ui/core/styles/styled';
import Container from '@material-ui/core/Container';

const RequestMediaPermissions = () => {
  const dispatch = useDispatch();
  const permissionsStep = useSelector(state => state.messageState.permissionStep);
  const [title, setTitle] = useState('Please Wait...');
  const [text, setText] = useState(false);

  useComponentWillMount(() => {
    dispatch(getMediaPermissions());
  });

  useEffect(() => {
    if (permissionsStep !== 'unknown') {
      setTitle('We need your permission!');
      setText(true);
    }
  }, [permissionsStep]);

  return (
    <ContainerDiv>
      <LightTitle variant="h4" align="center">
        {title}
      </LightTitle>
      <br />
      {text && <MainText>VirtualCare needs access to your camera and microphone for your consultation</MainText>}
      <br />
      {text && <MainText>Please follow the steps below:</MainText>}
      <br />
      {text && <MainText>1. Click on the 'Grant Permission' button below</MainText>}
      {text && <MainText>2. Click 'ALLOW/ACCEPT' on the pop-ups that appear</MainText>}
      <br />
      {text && (
        <PreConsultActionButton
          id="GivePermissionButton"
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => {
            dispatch(setConsultState('pre-consult'));
            dispatch(initVideo());
          }}
        >
          Grant Permission
        </PreConsultActionButton>
      )}
      {text && <SubText>Still having trouble? Please check the camera and microphone settings on your device.</SubText>}
    </ContainerDiv>
  );
};

export default RequestMediaPermissions;

const ContainerDiv = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15,
});

const MainText = styled(Typography)({
  maxWidth: '95%',
  textAlign: 'center',
});

const SubText = styled(Typography)({
  maxWidth: '95%',
  textAlign: 'center',
  textSize: '0.75em',
});
