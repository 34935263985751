import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
    endCallFab: {
      margin: theme.spacing(1),
      background: '#F22F46',
      color: 'white'
    }
  })
);

export default function EndCallButton() {
  const classes = useStyles();
  const { room, setHasDisconnected} = useVideoContext();

  const disconnect = () => {
    room.disconnect();
    setHasDisconnected(true);
  };

  return (
    <Tooltip title={'End Call'} onClick={() => disconnect() } placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.endCallFab}>
        <CallEnd />
      </Fab>
    </Tooltip>
  );
}
