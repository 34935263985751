import React from 'react';
import { disabledColor } from './consts';

const Chrome = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-label="Chrome" viewBox="0 0 512 512" {...props}>
      <path
        fill={props.disabled ? disabledColor : '#F44336'}
        d="M61.818,89.204c119.008-138.496,339.68-111.168,422.144,50.624c-58.08,0.032-148.992-0.032-198.24,0
	c-35.712,0-58.784-0.8-83.744,12.352c-29.344,15.456-51.52,44.096-59.232,77.76L61.818,89.204z"
      />
      <path
        fill={props.disabled ? disabledColor : '#2196F3'}
        d="M170.842,256.02c0,46.944,38.176,85.12,85.088,85.12s85.088-38.176,85.088-85.12
	s-38.176-85.12-85.088-85.12C208.986,170.868,170.842,209.076,170.842,256.02z"
      />
      <path
        fill={props.disabled ? disabledColor : '#4CAF50'}
        d="M288.922,367.444c-47.744,14.176-103.648-1.536-134.24-54.4
	c-23.36-40.32-85.12-147.872-113.152-196.8C-56.774,266.9,27.93,472.18,206.362,507.22L288.922,367.444z"
      />
      <path
        fill={props.disabled ? disabledColor : '#FFC107'}
        d="M334.938,170.868c39.776,36.992,48.448,96.896,21.504,143.328
	c-20.288,35.008-85.088,144.352-116.48,197.28c183.84,11.328,317.856-168.832,257.312-340.64L334.938,170.868z"
      />
    </svg>
  );
};

export default Chrome;
