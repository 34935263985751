import React from 'react';
import { disabledColor } from './consts';

const InternetExporer = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" aria-label="IE" viewBox="0 0 512 512" {...props}>
      <path
        fill={props.disabled ? disabledColor : '#00C8F6'}
        d="M179.649,292.487c0,17.067,7.186,31.439,15.27,46.709c8.982,16.168,21.558,28.744,37.726,38.625
	s33.235,14.372,52.996,14.372c18.863,0,36.828-4.491,52.996-14.372c16.168-9.881,27.846-24.253,37.726-40.421h125.754
	c-16.168,45.811-44.014,85.333-84.435,114.077s-85.333,44.014-134.737,44.014c-36.828,0-72.758-8.084-105.095-24.253
	c-73.656,35.93-126.653,37.726-158.989,5.389C5.389,465.848,0,447.883,0,423.631s4.491-52.098,14.372-81.74
	c9.881-30.54,25.151-62.877,47.607-99.705c17.067-28.744,29.642-51.2,78.147-97.909c17.965-17.965,26.049-26.947,30.54-31.439
	c-43.116,20.66-98.807,59.284-143.719,98.807c26.947-66.47,64.674-100.604,110.484-129.347
	c49.404-29.642,89.825-49.404,143.719-49.404c5.389,0,10.779,0,17.067,0.898C337.74,15.827,373.67,6.845,406.007,4.15
	c32.337-1.796,54.793,3.593,68.267,16.168c26.947,26.947,28.744,70.063,7.186,128.449c20.66,35.93,21.558,76.351,21.558,117.67
	c0,9.881,0.898,18.863,0,26.947H386.246H179.649V292.487z"
      />
      <path
        fill="#FFFFFF"
        d="M143.719,445.189c-46.709-28.744-62.877-52.098-81.74-103.298
		c-31.439,61.081-40.421,88.926-17.067,112.281C65.572,474.831,89.825,469.441,143.719,445.189"
      />
      <path
        fill="#FFFFFF"
        d="M377.263,229.61c-1.796-25.151-10.779-47.607-29.642-64.674
		c-18.863-17.965-41.319-25.151-66.47-25.151c-26.049,0-47.607,7.186-66.47,25.151s-32.337,39.523-34.133,64.674H377.263z"
      />
      <path
        fill="#FFFFFF"
        d="M366.484,49.062c43.116,21.558,50.302,25.151,82.639,63.775
		c10.779-29.642,12.575-37.726,8.084-46.709c-4.491-10.779-13.474-18.863-23.354-24.253
		C417.684,32.894,406.905,32.894,366.484,49.062"
      />
    </svg>
  );
};

export default InternetExporer;
