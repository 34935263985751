import { Theme } from "@material-ui/core/styles";

const theme = (outerTheme: Theme) => ({
  ...outerTheme,
  typography: {
    ...outerTheme.typography,
    h1: {
      ...outerTheme.typography.h1,
      fontSize: 36,
      fontWeight: 400
    },
  }
});

export default theme;