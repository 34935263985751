import { setConsultState, setPermissionStep } from '../actions';

export const getPermissions = async dispatch => {
  navigator.mediaDevices
    .enumerateDevices()
    .then(devices => devices.every(device => !(device.deviceId && device.label)))
    .then(shouldAskForMediaPermission => {
      if (shouldAskForMediaPermission) {
        dispatch(setPermissionStep('prompt'));
      } else {
        dispatch(setConsultState('pre-consult'));
      }
    });
};

export const getDevices = () => {
  navigator.mediaDevices.enumerateDevices();
};
