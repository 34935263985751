import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';

import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';

import MainLogo from '../../assets/images/brand_logo.png';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

export const TOOLBAR_HEIGHT = 64;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      maxHeight: TOOLBAR_HEIGHT,
      minHeight: TOOLBAR_HEIGHT,
    },
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    joinButton: {
      margin: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      color: theme.palette.text.primary,
    },
  })
);

export default function SimpleMenuBar() {
  const classes = useStyles();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={MainLogo} style={{ height: '40px', marginLeft: '5px' }} alt="Logo" />
        </div>

        <Typography className={classes.displayName} variant="body1">
          VirtualCare
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
