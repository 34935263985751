import React from "react";


import { styled } from "@material-ui/core/styles";
import { Typography, Fade } from "@material-ui/core";


const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});



export default (props: { room: any }) => {


  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>

      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Future booking</LightTitle>
        <Typography align='center'>Please join on {props.room.booking.date} at {props.room.booking.time}</Typography>
      </PreJoinContiner>
    </Fade>
  );
}