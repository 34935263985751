import React from "react";

import { styled } from "@material-ui/core/styles";
import { Typography, CircularProgress, Fade } from "@material-ui/core";

const PreJoinContiner = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const LightTitle = styled(Typography)({
  fontWeight: 300,
  paddingTop: 15,
  paddingBottom: 15
});

const Spinner = styled(CircularProgress)({
  marginTop: 15
});

export default () => {
  return (
    <Fade in={true} timeout={{ enter: 500, exit: 0 }}>
      <PreJoinContiner>
        <LightTitle variant="h4" align='center'>Getting ready to join</LightTitle>
        <Typography>You will be able to join in just a moment</Typography>
        <Spinner size={25}/>
      </PreJoinContiner>
    </Fade>
  );
}