import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, styled } from "@material-ui/core/styles";

import Controls from "../Controls/Controls";
import LocalVideoPreview from "../LocalVideoPreview/LocalVideoPreview";

import GettingReady from "./Instructions/GettingReady";
import ReadyToJoin from "./Instructions/ReadyToJoin";
import ConnectedFromAnotherDevice from "./Instructions/ConnectedFromAnotherDevice";

import { sendMessage, startConsult, setIsReady } from "../../redux/message/actions";
import PatientReady from "./Instructions/PatientReady";
import PractitionerReady from "./Instructions/PractitionerReady";
import FutureLink from "./Instructions/FutureLink";
import InvalidLink from "./Instructions/InvalidLink";
import StartingConsult from "./Instructions/StartingConsult";
import { useAppState } from "../../state";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";

const GRID_PADDING = 15;

const LocalVideoContainer = styled("div")({
  alignItems: "end",
  justifyItems: "center",
  width: "100%",
  height: "100%"
});

const useStyles = makeStyles(() =>
  ({
    preJoinGrid: {
      display: "grid",
      gridTemplateRows: (props: { videoHeight: number }) => `minmax(0, 1fr) ${props.videoHeight}px`,
      gridTemplateColumns: "1fr",
      padding: GRID_PADDING
    }
  })
);

const calculateVideoHeight = (height: number) => Math.floor((height - GRID_PADDING * 2) / 3 * 2);
const calculateVideoWidth = (width: number) => Math.floor(width - GRID_PADDING * 2);

type ConnectFunction = () => any;


export default (props: {
  width: number, height: number, connectSocket: ConnectFunction
}) => {

  const dispatch = useDispatch();

  const { getTokenCustom, setUserType, setUserToken} = useAppState();
  const { connect} = useVideoContext();

  const [videoHeight, setVideoHeight] = React.useState<number>(calculateVideoHeight(props.height));
  const [videoWidth, setVideoWidth] = React.useState<number>(calculateVideoWidth(props.width));
  const classes = useStyles({ videoHeight });

  const room: any = useSelector((s: any) => s.messageState.room);
  const connectedFromAnotherDevice: object = useSelector((s: any) => s.messageState.connectedFromAnotherDevice);
  const isReady: object = useSelector((s: any) => s.messageState.isReady);
  const consultStarted: object = useSelector((s: any) => s.messageState.consultStarted);
  const consultToken: any = useSelector((s: any) => s.messageState.token);

  const shouldConnect: object = useSelector((s: any) => s.socketState.shouldConnect);
  const connected: object = useSelector((s: any) => s.socketState.connected);

  React.useEffect(() => {
    setVideoHeight(calculateVideoHeight(props.height));
    setVideoWidth(calculateVideoWidth(props.width));
  }, [props.height, props.width]);


  const onReady = () => {
    dispatch(sendMessage({ action: "update-status", status: "ready" }));
    dispatch(setIsReady(true));
  };

  const onReconnect = () => {
    dispatch(setIsReady(false));
    props.connectSocket();
  };

  const onStartConsult = () => {
    dispatch(startConsult());
  };

  const onLaunchConsult = () => {
    getTokenCustom(consultToken).then(({token, type}) => {
      setUserType(type);
      setUserToken(token);
      connect(token);
    }).catch((err) => console.log(err));
  };

  const renderInstruction = () => {


    if (shouldConnect && (!connected || (connected && room === null)))
      return <GettingReady/>;

    if (connectedFromAnotherDevice)
      return <ConnectedFromAnotherDevice onReconnect={onReconnect}/>;

    if (connected && (room && room.token_is_valid && room.token_is_for_today) && !isReady)
      return <ReadyToJoin onReady={onReady} room={room}/>;

    if (connected && (room && room.token_is_valid && !(room.token_is_for_today) && !(room.token_is_expired)))
      return <FutureLink room={room}/>;

    if (connected && (room && room.token_is_valid && room.token_is_expired))
      return <InvalidLink room={room}/>;

    if (isReady && !consultStarted && (room && room.consult_started === "No")) {

      if (room.user_type === "patient") return <PatientReady room={room}/>;
      if (room.user_type === "practitioner") return <PractitionerReady room={room} onStartConsult={onStartConsult}/>;

      return <p>Ready</p>;
    }

    if (consultStarted || (room && room.consult_started === "Yes")) {
      return <StartingConsult room={room} onLaunchConsult={onLaunchConsult}/>;
    }

    return <p>Your link is invalid or expired</p>;
  };


  return (
    <LocalVideoContainer className={classes.preJoinGrid}>
      {renderInstruction()}
      <LocalVideoPreview maxHeight={videoHeight} maxWidth={videoWidth}>
        <Controls isLocal/>
      </LocalVideoPreview>
    </LocalVideoContainer>
  );
}

