import { CONNECTION_CHANGED, REQUEST_SOCKET_CONNECT, REQUEST_SOCKET_DISCONNECT } from '../actions';

const INITIAL_STATE = {
  connected: false,
  shouldConnect: false
};

function socketReducer(state = INITIAL_STATE, action: { type: string, port: string, connected: boolean }) {
  let reduced;
  switch (action.type) {
    case CONNECTION_CHANGED:
      reduced = Object.assign({}, state, {
        connected: action.connected,
        isError: false
      });
      break;
    case REQUEST_SOCKET_CONNECT:
      return {...state, shouldConnect: true};
    case REQUEST_SOCKET_DISCONNECT:
      return {...state, shouldConnect: false};
    default:
      reduced = state;
  }
  return reduced;
}

export default socketReducer;